/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type AnnounceInput = {
  queryExecutionId?: string | null;
  file?: S3ObjectInput | null;
};

export type S3ObjectInput = {
  bucket: string;
  region: string;
  key: string;
};

export type AthenaQueryResult = {
  __typename: 'AthenaQueryResult';
  queryExecutionId?: string;
  file?: S3Object;
};

export type S3Object = {
  __typename: 'S3Object';
  bucket?: string;
  region?: string;
  key?: string;
};

export type StartDataExportQueryInput = {
  startDate?: string | null;
  endDate?: string | null;
  recordType?: RecordType | null;
  privateOutputFolder: string;
};

export enum RecordType {
  ALL = 'ALL',
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
}

export type StartDataExportQueryPayload = {
  __typename: 'StartDataExportQueryPayload';
  dataExportQueryExecutionId?: string;
};

export type StartLogExportQueryInput = {
  startDate?: string | null;
  endDate?: string | null;
  countries?: Array<string> | null;
  votingStates?: Array<string> | null;
  congressionalDistricts?: Array<string> | null;
  leoJurisdictions?: Array<string> | null;
  joinDa?: JoinDa | null;
  party?: Party | null;
  site?: Site | null;
  recordType?: RecordType | null;
  privateOutputFolder: string;
};

export enum JoinDa {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  ALREADY_A_MEMBER = 'ALREADY_A_MEMBER',
}

export enum Party {
  DEMOCRATIC = 'DEMOCRATIC',
  REPUBLICAN = 'REPUBLICAN',
  OTHER = 'OTHER', // everything else
  PREFER_NOT_TO_ANSWER = 'PREFER_NOT_TO_ANSWER',
  NO_PARTY_PREFERENCE = 'NO_PARTY_PREFERENCE', // "none" in database
}

export enum Site {
  STANDARD = 'STANDARD',
  SPANISH = 'SPANISH',
  STUDENT = 'STUDENT',
  BETA = 'BETA',
  NO_ANSWER = 'NO_ANSWER',
}

export enum VoterClass {
  INTEND_TO_RETURN = 'INTEND_TO_RETURN',
  UNCERTAIN_RETURN = 'UNCERTAIN_RETURN',
  NEVER_RESIDED = 'NEVER_RESIDED',
  MILITARY = 'MILITARY',
  NAT_GUARD = 'NAT_GUARD',
}

export type StartLogExportQueryPayload = {
  __typename: 'StartLogExportQueryPayload';
  dataExportQueryExecutionId?: string;
};

export type StartLogCountQueryInput = {
  startDate?: string | null;
  endDate?: string | null;
  countries?: Array<string> | null;
  votingStates?: Array<string> | null;
  congressionalDistricts?: Array<string> | null;
  leoJurisdictions?: Array<string> | null;
  joinDa?: JoinDa | null;
  party?: Party | null;
  site?: Site | null;
  recordType?: RecordType | null;
  privateOutputFolder: string;
};

export type StartLogCountQueryPayload = {
  __typename: 'StartLogCountQueryPayload';
  dataCountQueryExecutionId?: string;
};

export type StartLogHistogramQueryInput = {
  startDate?: string | null;
  endDate?: string | null;
  period?: HistogramPeriod | null;
  countries?: Array<string> | null;
  votingStates?: Array<string> | null;
  congressionalDistricts?: Array<string> | null;
  leoJurisdictions?: Array<string> | null;
  joinDa?: JoinDa | null;
  party?: Party | null;
  site?: Site | null;
  recordType?: RecordType | null;
  privateOutputFolder: string;
  groupBy?: HistogramGroupBy | null;
};

export enum HistogramPeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum HistogramGroupBy {
  VOTER_CLASS = 'VOTER_CLASS',
  PARTY = 'PARTY',
  JOIN_DA = 'JOIN_DA',
  GPP = 'GPP',
  COUNTRY = 'COUNTRY',
  VOTING_STATE = 'STATE',
  CONGRESSIONAL_DISTRICT = 'CONGRESSIONAL_DISTRICT',
}

export type StartLogHistogramQueryPayload = {
  __typename: 'StartLogHistogramQueryPayload';
  dataHistogramQueryExecutionId?: string;
};

export type AnnounceQueryResultMutationVariables = {
  input?: AnnounceInput;
};

export type AnnounceQueryResultMutation = {
  announceQueryResult?: {
    __typename: 'AthenaQueryResult';
    queryExecutionId: string;
    file?: {
      __typename: 'S3Object';
      bucket: string;
      region: string;
      key: string;
    } | null;
  } | null;
};

export type StartDataExportQueryMutationVariables = {
  input?: StartDataExportQueryInput;
};

export type StartDataExportQueryMutation = {
  startDataExportQuery: {
    __typename: 'StartDataExportQueryPayload';
    dataExportQueryExecutionId: string;
  };
};

export type StartLogExportQueryMutationVariables = {
  input?: StartLogExportQueryInput;
};

export type StartLogExportQueryMutation = {
  startLogExportQuery: {
    __typename: 'StartLogExportQueryPayload';
    dataExportQueryExecutionId: string;
  };
};

export type StartLogCountQueryMutationVariables = {
  input?: StartLogCountQueryInput;
};

export type StartLogCountQueryMutation = {
  startLogCountQuery: {
    __typename: 'StartLogCountQueryPayload';
    dataCountQueryExecutionId: string;
  };
};

export type StartLogHistogramQueryMutationVariables = {
  input?: StartLogHistogramQueryInput;
};

export type StartLogHistogramQueryMutation = {
  startLogHistogramQuery: {
    __typename: 'StartLogHistogramQueryPayload';
    dataHistogramQueryExecutionId: string;
  };
};

export type MeQuery = {
  me?: string | null;
};

export type OnAnnouncementSubscriptionVariables = {
  queryExecutionId?: string;
};

export type OnAnnouncementSubscription = {
  onAnnouncement?: {
    __typename: 'AthenaQueryResult';
    queryExecutionId: string;
    file?: {
      __typename: 'S3Object';
      bucket: string;
      region: string;
      key: string;
    } | null;
  } | null;
};
